<!-- 鹰视头部 -->
<template>
  <div class="cpt-application_header">
    <div class="home-header_inner flexs">
      <div class="landi"></div>
      <div class="yingshi" @click="handleClick"></div>
      <div class="flex" style="">
        <template>
          <div class="gundong">
            <ul class="ul">
              <li
                class="tap"
                v-for="item in optionsLeft"
                :key="item.id"
                @click="tap_click(item)"
              >
                <span>{{ item.title }}</span>
              </li>
            </ul>
          </div>
        </template>
      </div>
      <div class="flexs youbian">
        <tianqi class="tianqi"></tianqi>
        <div class="mode_nameBox cp" v-if="flightMode">
          <!-- -->
          <el-dropdown trigger="click" @command="handle_change_uav_mode">
            <span class="dib">
              <span class="mode_name">{{ flightMode }}</span>
              <i class="el-icon-caret-bottom"></i>
            </span>
            <div class="el-popperBox">
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="item in modeList"
                  v-show="!item.disabled"
                  :key="item.id"
                  :command="item.data"
                  >{{ item.label }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </div>
          </el-dropdown>
        </div>
        <HeaderRight :data="user" />
      </div>
    </div>
  </div>
</template>

<script>
import HeaderRight from "./header-right/index";
// 引用专门的天气预报
// import tianqi from "./tianqi/index.vue";
import tianqi from "./tianqi";

// 引用tap背景
import TapLan from "@/assets/newImage/taplan.png";
import TapJin from "@/assets/newImage/tapJin.png";
export default {
  props: {
    title: {
      type: String,
      default: () => "鹰视",
    },
    user: {
      type: Object,
      default: () => ({}),
    },
    uavData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      optionsLeft: [
        { id: 1, title: "无人机应用", url: "#/fckernel" },
        { id: 2, title: "鹰巢应用", url: "#/fckernel?page=2" },
        { id: 3, title: "视频墙", url: "#/fckernelVideos" },
      ],
      modeList: [
        { id: 1, data: "11", label: "安全降落", disabled: false },
        { id: 2, data: "12", label: "返航模式", disabled: false },
        { id: 3, data: "13", label: "航线模式", disabled: false },
        { id: 4, data: "17", label: "gps模式", disabled: false },
      ],
      TapLan: TapLan,
      TapJin: TapJin,
      flightMode: "",
      tap: [
        { id: "1", name: "首页", path: "/observe/home?page=1" },
        { id: "2", name: "飞行应用", path: "/observe?page=3" },
        // { id: "3", name: "AI应用", path: "/aiUse" },
      ],
      jinId: 99,
    };
  },
  watch: {
    uavData: function (val, old) {
      if (val) {
        this.flightMode = val.flightMode;
      }
    },
    deep: true,
  },
  components: { HeaderRight, tianqi },
  methods: {
    tap_click(item) {
      window.open(item.url, "_blank");
    },
    handle_change_uav_mode(item) {
      console.log(item, "item");
      this.$emit("control_ws", item);
    },
    btn(path) {
      // console.log(1);
      // this.dianji = index;
      window.open("#" + path, "_self");
    },
    handleClick() {
      // window.open('#/home', "_blank");
      // this.$router.push('/home')

      let { href } = this.$router.resolve({ path: "/home" });
      window.open(href, "_self");
      setTimeout(() => {
        this.$router.go(0);
      }, 100);
    },
  },
  mounted() {
    // this.btn("/observe/home?page=1",0)
    // console.log(this.$route.path,'>>>')
    if (this.$route.path == "/observe/home") {
      this.jinId = 0;
    } else if (this.$route.path == "/observe") {
      this.jinId = 1;
    } else if (this.$route.path == "/aiUse") {
      this.jinId = 2;
    }
  },
};
</script>

<style lang="scss" scoped>
.cpt-application_header {
  pointer-events: auto;
  height: 80px;
  box-sizing: border-box;
  background: #19223d;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background: linear-gradient(180deg, #19223d 0, transparent);
  .home-header_inner {
    height: 100%;
    position: relative;
    background-image: url("~@/assets/new_yingshi/yingshi_tou.png");
    background-size: 100% 100%;
    // min-width: 1920px;
    width: 100vw;
    position: relative;
    .landi {
      position: absolute;
      top: 0;
      left: 0;
      background-image: url("~@/assets/newImage/landi.png");
      background-size: 100% 100%;
      z-index: -99;
      width: 100%;
      height: 100%;
    }
    .header-bg_box {
      pointer-events: none;
      position: absolute;
      height: 139px;
      top: 0;
      right: 0;
      left: 0;
      background: center -10px url("~@/assets/ying_shi.png") no-repeat;
      background-size: auto 100%;
      .header-text {
        font-family: PangMenZhengDao;
        font-size: 40px;
        color: #ffffff;
        text-align: center;
        font-weight: 400;
        line-height: 109px;
      }
    }
    .flex {
      display: flex;
      align-items: center;
      margin-left: 137px;
      .gundong {
        position: relative;
        ul {
          left: 0px;
          list-style: none;
          // width: 100%;
          // height: 100%;
          display: flex;
          // overflow: scroll;
          .tap {
            width: 196px;
            height: 46px;
            position: relative;
            text-align: center;
            margin: 0 0px 0 0;
            display: flex;
            justify-content: center;
            align-items: center;
            // min-width: 133px;
            img {
              position: absolute;
              width: 134px;
              height: 34px;
              top: -10px;
              left: 0;
              cursor: pointer;
              // min-width: 133px;
            }
            span {
              font-size: 24px;
              font-family: YouSheBiaoTiHei;
              color: #ffffff;
              line-height: 30px;
              background: linear-gradient(
                180deg,
                #ffffff 0%,
                #ffffff 36%,
                #7897dc 100%
              );
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
          .tap {
            background-image: url("~@/assets/newImage/taplan1027.png");
            background-size: 100% 100%;
            line-height: 29px;
            cursor: pointer;
          }
          .tap:hover {
            background-image: url("~@/assets/newImage/tapJin1027.png");
            background-size: 100% 100%;
          }
        }
      }
    }
  }
}
// -----------------------------------------------------
.flexs {
  display: flex;
}
.yingshi {
  // font-family: PangMenZhengDao;
  // cursor: pointer;
  // font-weight: 400;
  // line-height: 72px;
  // margin: 0 0 0 5%;

  // font-size: 55px;

  // background-image: -webkit-linear-gradient(bottom, #f9ecd3, #e3aa77);
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
  margin: 5px 0 0 5%;
  width: 120px;
  height: 80%;
  cursor: pointer;
}
.youbian {
  margin: 0 0 0 5.5%;
  flex: 1;
  justify-content: right;
  align-items: center;
}
.tianqi {
  // padding: 7px 0 0 0;
  margin: 0 0 0 60px;
}
.xiaobeijing {
  background-image: url("~@/assets/newImage/taplan.png");
  background-size: 100% 100%;
}
.xiaobeijing:hover {
  background-image: url("~@/assets/newImage/tapJin.png");
  background-size: 100% 100%;
}
.jin {
  background-image: url("~@/assets/newImage/tapJin.png");
  background-size: 100% 100%;
}
.mode_nameBox {
  width: 116px;
  height: 30px;
  margin-top: 22px;
  background: url("~@/assets/images/observe/fckernel/nest/select.png") no-repeat !important;
  background-size: contain;
  margin-right: 5px;
  padding-left: 15px;
  display: flex;
  align-items: center;

  .mode_name {
    display: inline-block;
    vertical-align: middle;
    text-align: right;
    color: #3df1ff;
    margin: 0 6px 0 0;
  }
}
.el-dropdown-menu {
  background: rgba(9, 32, 87, 0.5) !important;
  .el-dropdown-menu__item {
    background: rgba(9, 32, 87, 0.5);
    color: #fff !important;
  }
  .el-dropdown-menu__item:hover {
    color: #43deff !important;
    background-image: linear-gradient(
      90deg,
      rgba(25, 98, 136, 0.34) 0%,
      rgba(44, 135, 176, 0.7) 0%,
      rgba(26, 100, 139, 0.37) 74%,
      rgba(7, 61, 98, 0) 100%
    ) !important;
  }
}
.btn {
  background-image: url("~@/assets/new_yingshi/btn_lan.png");
  background-size: 100% 100%;
  width: 216px;
  height: 46px;
  margin: 10px 10px 0 0;
  display: flex;
  justify-content: center;
  // align-items: center;
  padding: 10px 0 0 0;
  box-sizing: border-box;
  overflow: hidden;
}
.btn:hover {
  background-image: url("~@/assets/new_yingshi/btn_jin.png");
  cursor: pointer;
}
.btn_text {
  // width: 111px;
  height: 19px;
}
.version-btn {
  background-image: url("~@/assets/images/observe/version-btn.png");
  background-size: 100% 100%;
  height: 24px;
  color: #ffc15b;
  font-size: 12px;
  width: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
</style>