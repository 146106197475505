<!-- 新版 鹰视首页 -->
<template>
  <div class="fierce_look">
    <div class="fierce_look_content">
      <div class="img_bg1" @click="openBlank('#/fckernelVideos')"></div>
      <div class="img_bg2" @click="openBlank('#/fckernel')"></div>
      <div class="img_bg3" @click="openBlank('#/fckernel?page=2')"></div>
    </div>
    <div class="pa fierce_look_v1 cp" @click="tolink(1)"></div>
  </div>
</template>
  
<script>
// 顶部
import API from "@/api";
export default {
  components: {
  },
  data() {
    return {
      uavOnline: 0,
      uavOffline: 0,
      nestOnline: 0,
      nestOffline: 0,

      startedTurn: false, //已经开始旋转
    };
  },
  created() {
    this.$store.commit("mapmanage/SET_VISIBLE_MAIN_MAP", false);
  },
  mounted() {
    document.title = "鹰视";
    // API.FCKERNEL.ListUAV({}).then(res => {
    API.DEVICE.List({ cate: 1 }).then((res) => {
      if (res.length > 0) {
        this.uavOnline = res[0].online;
        this.uavOffline = res[0].offline;
      }
    });

    API.NEST.getNestList().then((res) => {
      this.nestOnline = res.onLineCount;
      this.nestOffline = res.offLineCount;
    });
  },
  methods: {
      tolink(){
      this.$router.push({ path: "/observe/home/1" });

    },
    openBlank(url) {
      window.open(url, "_blank");
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.fierce_look {
  box-sizing: border-box;
  padding-top: 104px;
  width: 100%;
  height: 100vh;
  background-size: 100% 100%;
  position: fixed;
  left: 0;
  top: 0;

  .fierce_look_v1 {
    width: 150px;
    height: 50px;
    z-index: 90;
    bottom: 10px;
    right: 20px;
    background-image: url("~@/assets/img/homeChange1.png");
    background-size: contain;
    background-repeat: no-repeat;
  }

  .fierce_look_content {
    display: flex;
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 50%;
    width: 77%;
    transform: translate(-50%, -50%);
    justify-content: space-around;
    .img_bg1 {
      width: 302px;
      height: 400px;
      background-image: url("./img/1.png");
      background-repeat: no-repeat;
      background-size: 100%;
      cursor: pointer;
    }
    .img_bg1:hover {
      background-image: url("./img/1_active.png");
    }
    .img_bg2 {
      width: 302px;
      height: 400px;
      background-image: url("./img/2.png");
      background-size: 100%;
      background-repeat: no-repeat;
      cursor: pointer;
    }
    .img_bg2:hover {
      background-image: url("./img/2_active.png");
    }
    .img_bg3 {
      width: 302px;
      height: 400px;
      background-image: url("./img/3.png");
      background-repeat: no-repeat;

      background-size: 100%;
      cursor: pointer;
    }
    .img_bg3:hover {
      background-image: url("./img/3_active.png");
    }
  }
}

</style>
    