<!-- 目前只有鹰击头部用到 -->
<template>
  <div class="home-header-left_box">
    <div class="left-list_box">
      <div style="margin-right: 24px">
        <div class="wea-takeoff" v-if="weather.flyStatus == 0" style="color: #ffc15b">不宜起飞</div>
        <div class="wea-takeoff" v-else>适合飞行</div>
      </div>
      <div>
        <div class="weather-label">
          <img class="weather-label__img" src="~@/assets/newImage/fengxiang.png" alt />
          <span class="weather__text">{{ weather.windDirection || "无风" }}</span>
        </div>
        <div style="text-align: end">
          <span class="weather__text">{{ weather.windSpeed || 0 }}m/s</span>
        </div>
      </div>
      <div class="wea-icon_box">
        <img :src="weatherIcon" />
      </div>
      <div class="time_box">
        <div class="dates">{{ date.date }}</div>
        <div class="times">
          <div class="weeks">{{ date.week }}</div>
          <div class="hours">{{ date.hour }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api";
import { mapGetters } from "vuex";
import mqtt from "mqtt";
export default {
  computed: {
    date() {
      return this.g_date();
    },
    ...mapGetters(["user_info"]),
    // weather() {
    //   return this.g_weather();
    // },
  },
  inject: ["g_date", "handle_report_show", "g_weather", "g_cesium_resetJfq"],
  data() {
    return {
      data: {},
      list: {
        duty: [],
        check: [],
      },
      filter: {
        name: null,
        orgId: null,
      },
      options: [],
      weather: {
        humidity: 3,
        id: 8,
        light: 100,
        maxTemp: 22.8,
        minTemp: 10.3,
        rain: 0,
        temperature: 14.8,
        weather: "晴",
        weatherCode: "00",
        windDirection: 0,
        windPower: "0级 无风 0m/s",
        windSpeed: 0,
      },
      weatherIcon: null,
      mqtt: {
        client: null,
      }, //天气实时动态
    };
  },
  watch: {
    "weather.weather": function (val, old) {
      if (val) {
        this.$store.commit("checksafe/SET_WEATHER", val);
      }
    },
    deep: true,
  },
  methods: {
    int_mqtt() {
      const { VUE_APP_FCMQTT_URL } = process.env;
      let url = VUE_APP_FCMQTT_URL;

      this.mqtt.client = mqtt.connect(url, {
        // protocol: "mqtt",
        clientId: this.user_info["mmc-identity"],
      });
      let topic = "APRON/RECEIVE/14";
      // let topic = this.user_info["fullSign"];
      this.mqtt.client.subscribe(topic, (e) => {
        // console.log("init初始化完成", "subscribe topic");
      });
      let music_list = [];
      this.mqtt.client.on("message", (topic, payload, packet) => {
        const data = JSON.parse(packet.payload.toString());
        if (data.type == 2066) {
          const date = new Date();
          let time = date.getHours();
          this.weather = data.data;
          if (this.weather.rain == 0) {
            this.weather.rain = "00";
          } else {
            this.weather.rain = "07";
          }
          this.weatherIcon = require(`@/assets/images/weather/icon/${
            time >= 18 ? "night" : "day"
          }/${this.weather.rain}.png`);
          if (
            this.weather.windDirection == 0 ||
            this.weather.windDirection == 360
          ) {
            this.weather.windDirection = "北风";
          } else if (
            this.weather.windDirection > 0 &&
            this.weather.windDirection < 90
          ) {
            this.weather.windDirection = "东北风";
          } else if (this.weather.windDirection == 90) {
            this.weather.windDirection = "东风";
          } else if (
            this.weather.windDirection > 90 &&
            this.weather.windDirection < 180
          ) {
            this.weather.windDirection = "东南风";
          } else if (this.weather.windDirection == 180) {
            this.weather.windDirection = "南风";
          } else if (
            this.weather.windDirection > 180 &&
            this.weather.windDirection < 270
          ) {
            this.weather.windDirection = "西南风";
          } else if (this.weather.windDirection == 270) {
            this.weather.windDirection = "西风";
          } else if (
            this.weather.windDirection > 270 &&
            this.weather.windDirection < 360
          ) {
            this.weather.windDirection = "西北风";
          } else {
            this.weather.windDirection = "北风";
          }
          //上线要打开
        }
      });
    },
    async threeWeather() {
      let organId = { organId: this.user_info["departmentId"] };
      let res = await API.WEATHER.getNewWeather(organId);
      // this.weatherList = res;
      this.weather = res[0];
      // console.log(this.weather, "njfsnahjbhbasfhj");
      // this.dashboardBasicParameters();
      const date = new Date();
      let time = date.getHours();
      this.weather.conditionDay =
        time >= 18 ? this.weather.conditionNight : this.weather.conditionDay;
      this.weatherIcon = require(`@/assets/images/weather/icon/iconfont/W${
        time >= 18 ? this.weather.nightIcon : this.weather.dayIcon
      }.png`);
    },
    handle_detail() {
      let { href } = this.$router.resolve({ path: "/command?page=6" });
      window.open(href, "_blank");
    },
    async getCheckList() {
      let res = await API.PERSON.CheckInfo(this.filter);
      this.list.check = res || [];
    },
    async get_duty() {
      let data = await API.USER.GetDuty();
      this.data = data;
    },
    async getOptions() {
      let res = await API.DEPARTMENT.Drd();
      this.options = [res] || [];
    },
    async list_duty() {
      let data = await API.USER.ListDuty();
      this.list.duty = data || [];
    },
    handleChange(val) {
      if (val == 0) {
        let _this = this;
        this.$refs.cascader.$refs.panel.$el.addEventListener(
          "click",
          function () {
            _this.$refs.dropdown.show();
          }
        );
      } else {
        this.$refs.dropdown.show();
      }
    },
  },
  async mounted() {
    // this.int_mqtt();
    this.threeWeather();
    await this.getOptions();
    await this.getCheckList();
    await this.get_duty();
    await this.list_duty();
    // Promise.all([this.get_duty(), this.list_duty()]);
  },
};
</script>

<style lang="scss" scoped>
.home-header-left_box {
  display: flex;
  align-items: center;
  // height: 60px;
  z-index: 9;
  .left-list_box {
    display: flex;
    flex-wrap: nowrap;
    box-sizing: border-box;
    position: relative;
    align-items: center;
    .wea-icon_box {
      width: 36px;
      height: 36px;
      margin: 0px 24px 0 26px;
      img {
        width: 100%;
        height: 100%;
      }
      i {
        font-size: 40px;
        color: rgba(255, 206, 84, 1);
      }
    }
    .list-item_box {
      height: 100%;
      padding: 0 10px;
      position: relative;
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      .wea-detail_box {
        display: flex;
        .wea-info-group_box {
          display: flex;
          flex-direction: column;
          margin-right: 23px;
          &:last-child {
            margin-right: 0px;
          }
        }
        .wea-info_box {
          height: 50%;
          display: flex;
          align-items: center;
          .wea-title {
            font-family: Microsoft YaHei;
            font-size: 14px;
            color: #ffffff;
            letter-spacing: 0;
            font-weight: 400;
            margin-right: 4px;
          }
          .wea-icon_box {
            margin-right: 4px;
            width: 30px;
            height: 30px;
            img {
              width: 100%;
              height: 100%;
            }
            i {
              font-size: 30px;
              color: #ffc736;
            }
          }
          .wea-value {
            font-family: Microsoft YaHei;
            font-size: 16px;
            color: #00e4ff;
            letter-spacing: 0;
            font-weight: 700;
          }
        }
      }
      .wea-speed {
        font-family: Microsoft YaHei;
        font-size: 16px;
        color: #00e4ff;
        letter-spacing: 0;
        font-weight: 700;
        height: 50%;
      }
      .wea-takeoff {
        font-family: YouSheBiaoTiHei;
        font-size: 20px;
        letter-spacing: 0;
        font-weight: 400;
        height: 50%;
        &._1 {
          color: #6aff64;
        }
        &._2 {
          color: red;
        }
      }

      &.duty:hover {
        cursor: pointer;
        background: #00e4ff;
        .icon {
          .iconfont {
            color: #19223d;
          }
        }
        .text {
          color: #19223d;
        }
      }
      &::after {
        content: " ";
        display: block;
        width: 1px;
        height: 37px;
        position: absolute;
        top: 0;
        right: 0;
        transform: translateY(50%);
        background-image: linear-gradient(
          206deg,
          rgba(36, 146, 252, 0) 5%,
          #2492fc 56%,
          rgba(36, 146, 252, 0) 100%
        );
      }
      &:last-child::after {
        display: none;
      }
      .dates {
        font-family: UniDreamLED;
        font-size: 24px;
        color: #00e4ff;
        letter-spacing: 0;
        font-weight: 400;
        min-width: 110px;
      }
      .times {
        display: flex;
        .weeks {
          font-family: Microsoft YaHei;
          font-size: 20px;
          color: #00e4ff;
          letter-spacing: 0;
          font-weight: 800;
          margin-right: 8px;
        }
        .hours {
          min-width: 78px;
          font-family: UniDreamLED;
          font-size: 24px;
          color: #00e4ff;
          letter-spacing: 0;
          font-weight: 400;
        }
      }
      .icon {
        display: flex;
        justify-content: center;
        .iconfont {
          font-size: 36px;
          color: #00e4ff;
        }
      }
      .text {
        font-family: Microsoft YaHei;
        font-size: 10px;
        color: #43f0df;
        letter-spacing: 0;
        text-align: center;
        line-height: 10px;
        font-weight: 400;
      }
      .dep-info_box {
        display: flex;
        .dep-group_box {
          margin-right: 11px;
          &:last-child {
            margin-right: 0;
          }
          .dep-box {
            display: flex;
            align-items: center;
            .dep-title {
              font-family: Microsoft YaHei;
              font-size: 14px;
              color: #ffffff;
              letter-spacing: 0;
              font-weight: 400;
              margin-right: 4px;
              .iconfont {
                font-size: 26px;
              }
            }
            .dep-name {
              font-family: MicrosoftYaHei-Bold;
              font-size: 16px;
              color: #00e4ff;
              letter-spacing: 0;
              font-weight: 700;
            }
          }
        }
      }
    }
  }
}
.duty-list-box {
  border: 1px solid red;
  width: 400px;
  height: 455px;
  background-image: linear-gradient(180deg, #161d2d 0%, #0c2e4f 100%);
  border: 1px solid #00e4ff;
  box-shadow: 0 8px 16px 0 #101523;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 11px 9px;
  .hoverbgcolor {
    img:hover {
      cursor: pointer;
      background-color: rgb(11, 176, 176);
    }
  }
  .search-box {
    display: flex;
    margin-bottom: 15px;
    color: #08c2d1;
    justify-content: space-between;
    .detail {
      width: 45px;
      line-height: 40px;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
    .icon-daka {
      color: #21e0e0;
      font-size: 28px;
      line-height: 40px;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
    ::v-deep {
      .el-input,
      .el-cascader {
        width: 150px;
      }
      .el-input__inner {
        background: #000000;
        border: 1px solid #08c2d1;
        font-family: MicrosoftYaHeiUI;
        font-size: 16px;
        color: #08c2d1;
      }
      .el-button {
        width: 114px;
        height: 40px;
        padding: 10px 20px;
        background: #129c9c;
        font-family: PangMenZhengDao;
        font-size: 22px;
        color: #ffffff;
        border: none;
      }
    }
  }
  .hd_box {
    margin-bottom: 7px;
    height: 20px;
    .tr {
      display: flex;
      .th {
        flex: 1;
        font-family: MicrosoftYaHei;
        font-size: 12px;
        color: #43c7f0;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
      }
    }
  }
  .bd_box {
    height: calc(100% - 20px);
    overflow: auto;
    .tr {
      display: flex;
      .td {
        flex: 1;
        font-family: Microsoft YaHei;
        font-size: 12px;
        color: #9bffff;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
        height: 59px;
        display: flex;
        align-items: center;
        justify-content: center;
        &.group {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
      &:nth-last-of-type(odd) {
        background: #2b3857;
        box-shadow: inset 0 -1px 0 0 #275a61;
      }
      &:nth-last-of-type(even) {
        background: #1e2a45;
        box-shadow: inset 0 -1px 0 0 #275a61;
      }
    }
  }
  .bd_box_card {
    height: calc(100% - 80px);
  }
}
// 新片段
.ss {
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #00ffff;
  line-height: 21px;
}
.jl {
  margin: 0 18px 0 0;
  img {
    width: 19px;
    height: 19px;
  }
}
.wea-takeoff {
  font-size: 28px;
  font-family: YouSheBiaoTiHei;
  color: #6aff64;
}

.xian {
  margin: 7px 21px 0 10px;
  width: 1px;
  height: 41px;
  border-left: 1px solid;
  border-image: linear-gradient(
      180deg,
      rgba(0, 145, 255, 0),
      rgba(0, 193, 255, 1),
      rgba(0, 145, 255, 0)
    )
    2 2;
}
.time_box {
  font-size: 16px;
  font-family: MicrosoftYaHei;
  margin-right: 25px;
  
  color: #FFFFFF;
  .times {
    display: flex;
    justify-content: space-between;
  }
}

.weather-label{
  display: flex;
  align-items: center; 

  .weather-label__img{
    margin-right: 18px;
    height: 19px;
    width: 19px;
  }

  
}

.weather__text{
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #FFFFFF;
}
</style>