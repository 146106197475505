<template>
  <div>
    <video class="bk" autoplay muted loop controls="false" name="media" >
      <source src="~@/assets/new_yingshi/ys_bj.mp4" type="video/mp4">
    </video>
    <div class="observe-header">
      <observeHeader></observeHeader>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import index1 from './index1';
import index2 from './index2';
import observeHeader from "./components/header/index.vue";

export default {
  name: 'ObserveHome',
  components: {
    index1,
    index2,
    observeHeader
  },
  data(){
    return {
    }
  }
}
</script>

<style lang="scss" scoped>
.bk{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 1920px;
  height: 1080px;
}

//所有控件
video::-webkit-media-controls-enclosure{ 
    display: none;
}

.observe-header {
  width: 100%;
  height: 104px;
}
</style>